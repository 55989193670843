<template>
  <div></div>
</template>

<script>
import jwt from "jsonwebtoken";
import { HTTP } from "@/service/axios";

export default {
  data() {
    return {
      secret: process.env.VUE_APP_BASE_SECERT,
    };
  },
  mounted() {
    let decode = jwt.verify(
      this.$route.params.token,
      this.secret,
      function (err, res) {
        if (err) {
          return false;
        } else {
          return res;
        }
      }
    );

    if (decode) {
      this.$store.commit("SET_USER", decode.user);
      this.$store.commit("SET_TOKEN", this.$route.params.token);
      this.$store.commit("SET_MODE", 1);
      this.$i18n.locale = decode.lang === "th" ? "th" : "en";
      this.fetchSurvey(decode.survey_id);
    }
  },
  methods: {
    async fetchSurvey(surveyId) {
      try {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.$route.params.token}`;
        let res = await HTTP.get(`/settings/type/survey`);

        if (res.data.success) {
          var surveyType = "";
          if (surveyId == res.data.result.survey.period) {
            surveyType = "period";
          } else {
            surveyType = "casual";
          }

          this.$store.commit("SET_SURVEY_TYPE", surveyType);
          this.createTestPeriod(surveyType);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createTestPeriod(surveyType) {
      try {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.$route.params.token}`;

        let obj = {
          startTime: new Date(),
          surveyType: surveyType,
        };

        let res = await HTTP.post(`/log/test/periods`, obj);

        if (res.data.success) {
          this.$router.push("/");
        } else {
          this.$router.push("/404");
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>


